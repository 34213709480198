.inner {
  max-width: 70rem;
  margin: 0 auto;
}
.addressSearch {
  padding-top: 10px;
  padding-bottom: 10px;
}
.coordinateSearch {
  padding-bottom: 10px;
}
.mapDiv {
  min-height: 40vh;
  width: 100%;
  position: relative;
}
