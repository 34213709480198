.wrapper {
  padding: 20px;
  padding-bottom: 10px;
  /* max-width: 70rem; */
  margin: 0 auto;
  color: white;
  margin-bottom: 10px;
}
.background {
  background: #34e89e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0f3443,
    #34e89e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0f3443,
    #34e89e
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
