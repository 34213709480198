@font-face {
  font-family: "Woodwarrior";
  font-weight: bold;
  src: url("./fonts/Woodwarrior/Woodwarrior-Bold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Woodwarrior", sans-serif;
}

hr {
  background: #34e89e; /* fallback for old browsers */
  height: 5px;
  border-radius: 20px;
  border: 0;
}
